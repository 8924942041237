import React from 'react'
import '@fontsource/lexend'
import '@fontsource/lexend/300.css'
import '@fontsource/lexend/600.css'
import '@fontsource/lexend/900.css'
import '@fontsource/covered-by-your-grace/400.css'

import { ThemeProvider } from './src/theme/Provider'
import { ReactQueryProvider } from './src/providers/ReactQuery'

export const wrapRootElement = ({ element }) => (
  <ReactQueryProvider>
    <ThemeProvider>{element}</ThemeProvider>
  </ReactQueryProvider>
)
